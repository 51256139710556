import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GetNodesRequest } from '../models/get-nodes-request';
import { RequestOverviewItem } from '../models/request-overview-item';
import { GetNodesResponse } from '../models/get-nodes-response';
import { DigitalTwinNodeRelationsEnum } from '../models/digital-twin-node-relations.enum';
import { DigitalTwinNode } from '../models/digital-twin-node';
import { DigitalTwinGetContentResponse } from '../models/digital-twin-get-content-response';
import { CreateVehicleRequest } from '../models/create-vehicle-request';
import { SaveNodeResponse } from '../models/save-node-response';
import { BundleDeviation } from '../models/bundle-deviation';
import { InstalledVersionInfo } from '../models/installed-version-info';
import { GetNodeHistoryDiffRequest } from '../models/get-node-history-diff-request';
import { DigitalTwinDiff } from '../models/digital-twin-diff';

@Injectable({ providedIn: 'root' })
export class DigitalTwinApiService {
  private _uri: string;

  constructor(private _http: HttpClient) {
    this._uri = environment.cloudApiUri;
  }

  public getInstalledVersions(
    nodeId: number
  ): Observable<InstalledVersionInfo[]> {
    return this._http.get<InstalledVersionInfo[]>(
      `${this._uri}api/v3/store/twin/${nodeId}/installed-versions`
    );
  }

  public getBundleDeviations(nodeId: number): Observable<BundleDeviation[]> {
    return this._http.get<BundleDeviation[]>(
      `${this._uri}api/v3/store/twin/${nodeId}/bundle-deviations`
    );
  }

  public getVehicleTypes(): Observable<string[]> {
    return this._http.get<string[]>(`${this._uri}api/v3/store/vehicleTypes`);
  }

  public getBundleVersionsByVehicleType(
    vehicleType: string
  ): Observable<string[]> {
    return this._http.get<string[]>(
      `${this._uri}api/v1/releasemanager/bundleversions`,
      {
        params: { vehicleType }
      }
    );
  }

  public applyBundleVersions(
    nodeIdList: number[],
    vehicleType: string,
    bundleVersion: string
  ): Observable<number[]> {
    return this._http.post<number[]>(
      `${this._uri}api/v1/releasemanager/applybundle`,
      nodeIdList,
      {
        params: { vehicleType, bundleVersion }
      }
    );
  }

  public getNodeById(
    id: number,
    relations: DigitalTwinNodeRelationsEnum
  ): Observable<DigitalTwinNode> {
    return this._http.get<DigitalTwinNode>(
      `${this._uri}api/v3/store/twin/${id}`,
      {
        params: { relations: relations || DigitalTwinNodeRelationsEnum.None }
      }
    );
  }

  public deleteNodeById(id: number): Observable<object> {
    return this._http.delete(`${this._uri}api/v3/store/twin/${id}`);
  }

  public getNodeByVin(
    vin: string,
    relations: DigitalTwinNodeRelationsEnum
  ): Observable<DigitalTwinNode> {
    return this._http.get<DigitalTwinNode>(
      `${this._uri}api/v3/store/twin/vin/${vin}`,
      {
        params: { relations: relations || DigitalTwinNodeRelationsEnum.None }
      }
    );
  }

  public getNodeContentById(
    id: number
  ): Observable<DigitalTwinGetContentResponse> {
    return this._http.get<DigitalTwinGetContentResponse>(
      `${this._uri}api/v3/store/twin/${id}/content`
    );
  }

  public searchNode(request: GetNodesRequest): Observable<GetNodesResponse> {
    return this._http.post<GetNodesResponse>(
      `${this._uri}api/v3/store/search`,
      request
    );
  }

  public addVehicle(
    request: CreateVehicleRequest
  ): Observable<SaveNodeResponse> {
    return this._http.post<SaveNodeResponse>(
      `${this._uri}api/v3/store/twin/create`,
      request
    );
  }

  public getTFDRequestOverview(): Observable<RequestOverviewItem[]> {
    return this._http.get<RequestOverviewItem[]>(`${this._uri}api/tdf/get-all`);
  }

  public downloadReceivedData(item: RequestOverviewItem): void {
    this._downloadJsonResponse(
      `${this._uri}api/tdf/get-received-data/${item.id}`,
      `${item.sequenceNumber}_data.json`
    );
  }

  public downloadTDF(item: RequestOverviewItem): void {
    this._downloadJsonResponse(
      `${this._uri}api/tdf/get-tdf/${item.id}`,
      `${item.sequenceNumber}_tdf.json`
    );
  }

  public openTDF(item: RequestOverviewItem): void {
    this._downloadJsonResponse(
      `${this._uri}api/tdf/get-tdf/${item.id}`,
      `${item.sequenceNumber}_tdf.json`,
      true
    );
  }

  public getUserRoles(): Observable<string[]> {
    return this._http.get<string[]>(`${this._uri}api/v1/user`);
  }

  public getNodeDiff(
    request: GetNodeHistoryDiffRequest
  ): Observable<DigitalTwinDiff> {
    return this._http.post<DigitalTwinDiff>(
      `${this._uri}api/v3/store/twin/history/diff`,
      request
    );
  }

  private _downloadJsonResponse(
    uri: string,
    filename: string,
    viewOnly: boolean = false
  ): void {
    this._http
      .get(uri, {
        responseType: 'text'
      })
      .subscribe(response => {
        const newBlob = new Blob([response], { type: 'text/json' });
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = data;
        if (!viewOnly) {
          link.download = filename;
        }
        link.click();
      });
  }
}
