import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplyBundleDialogComponent } from '../apply-bundle/apply-bundle-dialog.component';
import { DigitalTwinDiff } from '../../models/digital-twin-diff';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-node-diff',
  templateUrl: './node-diff.component.html'
})
export class NodeDiffComponent {
  public fields = ['key', 'value.oldValue', 'value.newValue'];

  constructor(
    private _dialogRef: MatDialogRef<ApplyBundleDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      diff$: Observable<DigitalTwinDiff>;
      versionA: number;
      versionB: number;
    }
  ) {}

  public countKeys(value: object): number {
    return Object.keys(value).length;
  }

  public close(): void {
    this._dialogRef.close();
  }
}
